[data-demo-video] {
    visibility: hidden;
    background:rgba(0,0,0,0.2);
    max-width: 100%;
    height:0;
    margin:0;
}
[data-demo-video].show {
    display:flex;
    height:initial;
    visibility: visible;
}
[data-demo-video] video {
    width:100%;
    max-height:100%;
    transition: all 1s;
}
[data-demo-video].demo-video-embed {
    display:none;
    width:0;
    height:0;
}
[data-demo-video].demo-video-embed.show {
    display:flex;
    min-width:100%;
    max-width:100%;
    min-height:100px;
    width:100%;
    height:initial;
    margin:0.75rem auto 0 auto;
}
[data-demo-video].demo-video-embed video {
    display:none;
    min-width: 100px;
    width:0;
    height:0;
    margin:0 auto !important;
}
[data-demo-video].demo-video-embed.show video {
    display:block;
    margin:0 auto;
    width:100%;
    height:initial;
}

[data-demo-video].demo-video-overlay {
    display:none;
    position: absolute !important;
    margin:0 auto;
    top: 10vmin;
    left: 10vmin;
    width: 75vmin;
    height: 56vmin;
    border-radius: 3px;
    box-shadow: 0px 0px 3px white;
    z-index: 9999;
}
[data-demo-video].demo-video-overlay.show {
    display:flex;
    max-width:200%;
    min-width:80px;
    min-height:60px;
}


[data-demo-video].demo-video-fullscreen {
    display:none;
    position: absolute !important;
    margin:0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:0;
}
[data-demo-video].demo-video-fullscreen.show {
    display:initial;
}
[data-demo-video].demo-video-fullscreen video {
    width:100%;
    height:100%;
}
