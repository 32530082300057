[data-demo-qrcode] {
    display:flex !important;
    visibility:hidden;
    margin:0 auto;
    width:0;
    align-content:center;
    justify-content:center;
    transition:all 1s;
}
[data-demo-qrcode].show {
    visibility:visible;
    width:100%;
}
[data-demo-qrcode] canvas {
    width:0;
    transition:all 1s;
}
[data-demo-qrcode].show canvas {
    width:80%;
}
