[data-demo-controls] {
    visibility:hidden;
    max-height:0;
    margin:0 auto;
    opacity:0;
    transition:all 1s ease 0s, margin 1s ease 1s;
}
[data-demo-controls].show {
    visibility:visible;
    max-height:1000px;
    opacity:1;
    transition:all 1s ease 0s, margin 1s ease -1s;
}

[data-demo-media] {
    display: flex;
}
[data-demo-media] * {
    width:0;
    flex-grow:1;
    margin-left:0.5rem;
}

[data-demo-media] :first-child {
    margin-left:0;
}
